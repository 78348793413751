@tailwind base;
@tailwind components;
@tailwind utilities;

/* this feels like a hack */
.react-datepicker__aria-live {
  @apply hidden;
}

.react-datepicker__day-names {
  @apply mb-2 flex text-xs uppercase text-neutral-500;
}

.react-datepicker__day-name {
  @apply w-12 text-center;
}

.react-datepicker__current-month {
  @apply text-center;
}

.react-datepicker__week {
  @apply flex justify-between;
}

.react-datepicker__day {
  @apply m-1.5 flex h-9 w-9 cursor-pointer items-center justify-center rounded-full text-sm hover:bg-neutral-100;
}

.react-datepicker__day--outside-month {
  @apply text-neutral-500;
}

.react-datepicker__day--selected {
  @apply bg-ivy-300  text-green-500;
}

.react-datepicker__day--disabled {
  @apply opacity-25;
}
